/*------------------------*/
/* FONTS*/
/*------------------------*/
/* Webfont: Lato-Light */
@font-face {
  font-family: 'LatoWeb';
  src: url('./fonts/Lato-Regular.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Lato-Regular.woff2') format('woff2'),
    url('./fonts/Lato-Regular.woff') format('woff'),
    url('./fonts/Lato-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}
/* Webfont: Lato-Light */
@font-face {
  font-family: 'LatoWeb';
  src: url('./fonts/Lato-Regular.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Lato-Regular.woff2') format('woff2'),
    url('./fonts/Lato-Regular.woff') format('woff'),
    url('./fonts/Lato-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: 300;
  text-rendering: optimizeLegibility;
}
/* Webfont: Lato-Bold */
@font-face {
  font-family: 'LatoWeb';
  src: url('./fonts/Lato-Bold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Lato-Bold.woff2') format('woff2'), url('./fonts/Lato-Bold.woff') format('woff'),
    url('./fonts/Lato-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: bold;
  text-rendering: optimizeLegibility;
}
/* Webfont: Lato-Bold */
@font-face {
  font-family: 'LatoWeb';
  src: url('./fonts/Lato-Bold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Lato-Bold.woff2') format('woff2'), url('./fonts/Lato-Bold.woff') format('woff'),
    url('./fonts/Lato-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: 600;
  text-rendering: optimizeLegibility;
}
/* Webfont: Lato-Bold */
@font-face {
  font-family: 'LatoWeb';
  src: url('./fonts/Lato-Bold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Lato-Bold.woff2') format('woff2'), url('./fonts/Lato-Bold.woff') format('woff'),
    url('./fonts/Lato-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: 700;
  text-rendering: optimizeLegibility;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
