.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.new-line {
  white-space: pre;
}

.print-only {
  display: none;
}

@media print {
  @page {
    margin: 0;
  }

  .print-only {
    display: inherit;
  }

  body {
    margin: 0;
    color: black !important;
    font-family: 'LatoWeb', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  }

  .sale-entry-view {
    max-width: 7cm;
    color: black !important;
  }
  .ui.items > .item > .content > .description {
    font-size: 1.08em;
    color: #000;
    font-weight: normal;
  }
  .ui.header,
  .ui.items > .item > .content > .header {
    color: #000;
    font-weight: normal;
  }

  .no-print {
    display: none !important;
  }

  .ui.divided.items > .item {
    border-top: 1px solid black;
  }
  .ui.vertical.segment {
    border-top: 1px solid black;
  }
}

.ui.dropdown .menu .selected.item,
.ui.dropdown.selected {
  background: rgba(36, 38, 34, 0.15) !important;
}
