.printing-item {
  font-family: Helvetica, sans-serif;
  font-weight: 200;
  font-size: 10px;
  margin-bottom: 20px;
  max-width: 4.5cm;
  position: relative;
  min-height: 2.3cm;
  // border: 1px solid black;
}

.printing-item .item-description {
  font-size: 1.1em;
  text-transform: uppercase;
  line-height: 1;
  display: inline;
  white-space: pre-line;
}

.printing-item .modifiers {
  position: relative;
  font-size: 1em;
  line-height: 1;
}

.printing-item .header {
  font-size: 0.9em;
  border-bottom: 1px solid black;
  margin-bottom: 0.1em;
  padding-bottom: 0.1em;
}
.printing-item .line-total {
  font-size: 0.9em;
  line-height: 1;
  padding: 0.15em 1em;
  text-align: right;
  border-top: 1px solid black;
  position: absolute;
  width: 100%;
  bottom: 0;
}
